export const StarIcon = ({ svgColor }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="35"
        viewBox="0 0 38 35"
        fill="none"
      >
        <path
          d="M9.60329 1.40005C10.9921 0.606468 12.4205 0.388231 13.8886 0.745342C15.3965 1.10246 16.5868 1.99524 17.4598 3.42369L19.4239 6.75675L21.6261 3.54273C22.5784 2.19364 23.7886 1.38021 25.2568 1.10246C26.7646 0.785023 28.193 1.06278 29.5421 1.93572C30.8515 2.84835 31.6451 4.05856 31.9229 5.56637C32.2403 7.03451 31.9625 8.46296 31.0896 9.85173L29.7207 11.9944H32.28C33.8672 12.0738 35.1964 12.669 36.2677 13.78C37.3391 14.8513 37.8748 16.2004 37.8748 17.8273C37.7954 19.4541 37.2002 20.8032 36.0892 21.8745C35.0178 22.9062 33.6688 23.422 32.0419 23.422L29.2445 23.3625L30.673 25.8028C31.4666 27.1916 31.665 28.6399 31.2682 30.1477C30.911 31.6158 30.0579 32.7665 28.7088 33.5998C27.2804 34.3934 25.8321 34.6116 24.364 34.2545C22.8958 33.8577 21.7253 32.9847 20.8524 31.6356L18.8882 28.2431L16.686 31.5166C15.7734 32.826 14.5632 33.6394 13.0554 33.9569C11.5872 34.2346 10.1588 33.937 8.77002 33.0641C7.46061 32.1515 6.64718 30.9611 6.32975 29.493C6.05199 27.9852 6.34959 26.5369 7.22253 25.1481L8.59146 23.0649L6.03215 23.0054C4.44498 22.926 3.11573 22.3507 2.04439 21.2794C0.973048 20.1683 0.437378 18.7994 0.437378 17.1726C0.516736 15.5854 1.09209 14.2561 2.16343 13.1848C3.27444 12.1135 4.64338 11.5778 6.27023 11.5778L9.0081 11.6373L7.63916 9.19702C6.84558 7.80825 6.62734 6.3798 6.98446 4.91167C7.38125 3.44353 8.25419 2.273 9.60329 1.40005Z"
          fill="#E9DDDD"
          className={`${svgColor}`}
        />
      </svg>
    </>
  );
};
export const FileCopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.69951 14.699H4.79951C4.32212 14.699 3.86428 14.5094 3.52672 14.1718C3.18915 13.8342 2.99951 13.3764 2.99951 12.899V4.79902C2.99951 4.32163 3.18915 3.86379 3.52672 3.52623C3.86428 3.18866 4.32212 2.99902 4.79951 2.99902H12.8995C13.3769 2.99902 13.8347 3.18866 14.1723 3.52623C14.5099 3.86379 14.6995 4.32163 14.6995 4.79902V5.69902M11.0995 9.29902H19.1995C20.1936 9.29902 20.9995 10.1049 20.9995 11.099V19.199C20.9995 20.1931 20.1936 20.999 19.1995 20.999H11.0995C10.1054 20.999 9.29951 20.1931 9.29951 19.199V11.099C9.29951 10.1049 10.1054 9.29902 11.0995 9.29902Z"
        stroke="black"
        strokeWidth="2.37943"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
