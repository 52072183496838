import dogImg1 from "../../assets/img/footer-logo-img.png";
import dogImg2 from "../../assets/img/footer-logo-img-2.png";
import dogImg3 from "../../assets/img/footer-logo-img-3.png";
import dogImg4 from "../../assets/img/footer-logo-img-4.png";
import dogImg5 from "../../assets/img/footer-logo-img-5.png";
import dogImg6 from "../../assets/img/footer-logo-img-6.png";
import dogImg7 from "../../assets/img/footer-logo-img-7.png";

export const footerLogo = [
  {
    img: dogImg1,
  },
  {
    img: dogImg2,
  },
  {
    img: dogImg3,
  },
  {
    img: dogImg4,
  },
  {
    img: dogImg5,
  },
  {
    img: dogImg6,
  },
  {
    img: dogImg7,
  },
];

export const trumpDogMarquee = [
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
  {
    title: "trump dog",
  },
];
